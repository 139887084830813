// src/FormComponent.js
import React, { useState } from 'react';

const FormComponent = () => {
  const [name, setName] = useState('');
  const [className, setClassName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('https://auth-db937.hstgr.io/index.php?db=u781829316_contact/sub', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
    })
    .catch(error => {
      console.error('Error:', error);
    });
    
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Class:</label>
        <input
          type="text"
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          required
        />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default FormComponent;
